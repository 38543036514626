* {
  font-family: Calibri, sans-serif;
}
.dropdown-container {
  width: 100%;
  margin: 16px 0;
  font-family: Arial, sans-serif;
}

.dropdown-label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}

.dropdown-select {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.dropdown-header {
  font-weight: bold;
  background-color: #f9f9f9;
}

.selected-cost {
  margin-top: 8px;
  font-size: 16px;
  color: #333;
}
